<template>
	<div class="whiteBlock featuredVenues">
		<img v-for="venue in venues" :key="venue.id" :src="venue.photoUrl" alt="" />	
		<!-- <agile v-if="venues && venues.length >= 1" :nav-buttons="false" fade autoplay :autoplay-speed="3000">
			<div class="slide" v-for="venue in venues" :key="venue.id">
				<img :src="venue.photoUrl" alt="" />
			</div>
		</agile> -->
  </div>
</template>

<script>
	export default {
		props: ['venues'],
	}
</script>