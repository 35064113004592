<template>
	<div>
		<div>
		<!-- <HomeHero /> -->
		<!-- <Differentiate /> -->
		<!-- <FeaturedVenues :venues="featuredVenues" /> -->
		<transition name="fade">
			<div class="home" v-if="selectedImage != null">
			
				<div class="home__container" id="welcome" v-bind:style="{ backgroundImage: 'linear-gradient(136deg, rgba(0,0,0, 0.3), rgba(0,0,0, 0.5), rgba(0,0,0, 0.3)), url(' + selectedImage + ')' }">
					<div style="margin-bottom:5vh; margin-top:20vh;">
						<!-- <h1>Quality, <em>dependable,</em> on-demand workers with <em>accountable,</em> on-site support</h1> -->
						<h1 class="hidden-small">Workers for facilities, sports and entertainment, hotels, conventions, warehouses, and more.</h1>
						<h1 class="hidden-large">The best of today's gig workforce is at your disposal.</h1>
						<p class="page-intro hidden-small">The best of today's gig workforce is at your disposal.</p>
					<router-link :to="{name: 'quote'}">
						<button class="btn btn__primary btn__mega mt-5">Get a Quote</button>
					</router-link>
		      <!-- <button class="btn btn__primary btn__mega mt-5" @click="rModal()">Join our Team</button>
		        <transition name="modal">
		          <div v-if="register">
		            <Quote @close="closeModal()" />
		          </div>
		        </transition>
		      </div> -->
					<!-- <div class="buttonBox">
						
		        <router-link :to="{name: 'contactUs'}">
			        <button class="btn btn__primary btn__mega">Hire Our Team</button>
			      </router-link>
		      </div> -->
		    </div>

			</div>
		</div>
		</transition>
	<Jumpers />

	<div class="whyHire" id="checklist">
		<h2 class="montserrat">What Sets <span>J<em>u</em>mp</span> Apart</h2>
		<div class="checklist__wrapper">
			<div class="checklist--item">
				<div class="i-border">
					<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="300"></i>
				</div>
				<p class="new"><span class="strong">60,000+ Workers</span> - We have over 60,000 workers nationwide, with the capacity to handle just about any request in any city.</p>
				</div>
				<div class="checklist--item">
					<div class="i-border">
						<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="600"></i>
					</div>
					<p class="new"><span class="strong">Dedicated Service</span> - Enterprise customers enjoy account managers committed to their success. We are here to support you 100%.</p>
				</div>
				<div class="checklist--item">
					<div class="i-border">
						<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="900"></i>
					</div>
					<p class="new"><span class="strong">On-Site Support</span> - We are your on-site partners. We make sure your order is full, your workers are ready to work, and we deal with any on-site issues that come up.</p>
				</div>
				<div class="checklist--item">
					<div class="i-border">
						<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1200"></i>
					</div>
					<p class="new"><span class="strong">Shift Management</span> - We create your shifts, manage applications, and handle last-minute changes. We don't expect you to create and staff your own events!</p>
				</div>
				<div class="checklist--item">
					<div class="i-border">
						<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1500"></i>
					</div>
					<p class="new"><span class="strong">Industry Experience</span> - We have over a decade of industry experience and know how to handle complex orders and situations. </p>
				</div>
				<!-- <div class="checklist--item">
					<div class="i-border">
						<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1800"></i>
					</div>
					<p class="new"><span class="strong">Financial Stability</span> - Backed by investors, we have the capital and resources to ensure happy workers and strong relationships for years to come.</p>
				</div> -->
			</div>
		</div>
	</div>
	<VenueTypes />
</div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import Register from '@/components/Register.vue'
import Quote from '@/components/Quote.vue'
import LogoBanner from '@/components/LogoBanner.vue'
import Loader from '@/components/Loader.vue'
import HomeHero from '@/components/HomeHero.vue'
import Differentiate from '@/components/Differentiate.vue'
import Jumpers from '@/components/Jumpers.vue'
import FeaturedVenues from '@/components/FeaturedVenues.vue'
import VenueTypes from '@/components/VenueTypes.vue'

export default {
	name: 'home',
	data: () => ({
    // register: false,
    selectedImage: 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/wfewf.jpeg?alt=media&token=68dece69-ddb7-4d51-b04b-d87748386b13',
    images: [
    	'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/wfewf.jpeg?alt=media&token=68dece69-ddb7-4d51-b04b-d87748386b13',
    	'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_457451468-min.jpeg?alt=media&token=7b751e94-0faa-4d75-a145-f733b8ee261d',
    	'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_249236794-min.jpeg?alt=media&token=ce5fa650-160f-4736-8bda-f23f6ae926d6',
    	'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_182203732-min.jpeg?alt=media&token=ffab6102-1be1-4cb7-8462-c3a2125d2de0',
    	// 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/homepage%2FTrinityForest_11_3_LT-1900x1000.jpeg?alt=media&token=1c92b2d8-4d20-4f1b-8112-3cc51c048350',
		  // 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/homepage%2Fmile-high-stadium-denver-co-sports-authority-field-2048x1024-1-1600x800.jpeg?alt=media&token=4c024cca-83fc-4ae5-8eee-46d3814be9cf',
		  'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_149779674-min.jpeg?alt=media&token=c107d748-3b5e-4f72-87ff-9a87c77187b6',
		  'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_428723064-min.jpeg?alt=media&token=c67fb531-4fab-45f0-abfa-5982e27c682f',
		  'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/homepage%2F1641745009_998071_1641745083_noticia_normal.jpeg?alt=media&token=98bd87f9-7922-460a-a546-842e1e4389a0',
		  'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/homepage%2FBridgestoneArena.jpeg?alt=media&token=b001061b-d153-4e45-a3f9-ace3b110f562',
		  'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/homepage%2Fgab16951.jpeg?alt=media&token=c7e4796c-f185-4326-8e5a-108d1bc2ad34',
		  'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_53640763-min.jpeg?alt=media&token=aeeba9f9-227a-4639-ba13-def8f5cd9893',
		  'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/homepage%2Fandrew-ruiz-ab5jn3Ikj8M-unsplash.jpg?alt=media&token=f807f753-8132-4fd5-99ec-7595aab97568',
		  // 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/homepage%2F43F297A0-3B05-4575-8B95-417B47D79DDC.jpeg?alt=media&token=71c4ba40-33c8-4d01-925c-c77a9cbc566e',
		],
		// selectedImage: 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/wfewf.jpeg?alt=media&token=68dece69-ddb7-4d51-b04b-d87748386b13'
		// selectedImage: 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/AdobeStock_114976682%20(1)-min.jpeg?alt=media&token=ab458182-7f1b-4c66-a1fb-738f37e7b62b'
  }),

	computed: {
    ...mapState(['currentUser', 'userProfile']),

    // selectedImage() {
    // 	return setInterval(() => {
		// 		this.randomItem(this.images)
		// 	}, 3000)
    // }
  },
  components: {
    Register,
    Loader,
    LogoBanner,
    HomeHero,
    Quote,
    Differentiate,
    FeaturedVenues,
    Jumpers,
    VenueTypes
  },
  mounted: function () {
  	this.selectedImage = this.randomItem(this.images)
	  // this.animateSlider()
	  // setInterval(function(){ 
    //     this.selectedImage = this.randomItem(this.images)
    // }, 5000);
	},
  methods: {
  	animateSlider() {
  		return this.selectedImage = setInterval(() => {
				this.randomItem(this.images)
			}, 3000)
  	},
    // rModal() {
    //   this.register = true
    // },
    // closeModal() {
    //   this.register = false
    // },
    randomItem (items) {
      return items[Math.floor(Math.random()*items.length)];
    }
  },
  beforeDestroy () {
    // this.selectedImage = null,
    // this.register = false,
    // this.images = []
    // delete this.selectedImage,
    // delete this.register,
    // delete this.images
    // console.log(this)
    // this.$store.dispatch("clearTotalsState")
  },
  metaInfo: () => ({
    title: 'Jump',
    // titleTemplate: null,
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'On-demand staffing for live events, hotels, conventions, warehouses, and more',
    }],
  })
}
</script>