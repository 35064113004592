<template>
	<div class="modal-mask">
		<div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body pa-3">
          <form @submit.prevent>
          	<div class="flex justify-space-between align-center mb-2">
            	<h2>Create a Free Business Account</h2>
			        <i class="fas fa-times mr-1" @click="$emit('close')"></i>
			      </div>
            <div class="mb-3">
              <label for="firstname">First Name</label>
              <input v-model.trim="signupForm.firstname" autocomplete="given-name" type="text" placeholder="" id="firstname" required />
            </div>
            <div class="mb-3">
              <label for="lastname">Last Name</label>
              <input v-model.trim="signupForm.lastname" autocomplete="family-name" type="text" placeholder="" id="lastname" required />
            </div>
            <div class="mb-3">
              <label for="phone">Work Phone</label>
              <input v-model.trim="signupForm.phone" type="text" v-mask="'(###) ###-####'" placeholder="(###) ###-####" id="phone" required />
            </div>
            <div class="mb-3">
              <label for="email2">Work Email</label>
              <input v-model.trim="signupForm.email" autocomplete="email" type="text" placeholder="you@email.com" id="email2" required minlength="7" />
            </div>
            <div class="mb-3">
              <label for="company">Company</label>
              <input type="text" placeholder="" v-model.trim="signupForm.company" id="company" />
            </div>
            <div class="mb-3">
              <label for="password2">Password</label>
              <input v-model.trim="signupForm.password" autocomplete="new-password" type="password" placeholder="min 6 characters" id="password2" required minlength="6"/>
            </div>
            <button :disabled="!valid2" @click="signup()" class="btn btn__primary ml-0">Sign Up For Free</button>
            <div class="caption pt-3">
            	By clicking 'Sign up', you agree to MVP's <router-link :to="{name: 'terms'}">Terms of Use</router-link> and <router-link :to="{name: 'privacy'}">Privacy Policy</router-link>.
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped type="text/css">
  .signup-form {
    width: 96%;
    max-width: 100%;
    margin: auto;
}
</style>

<script>
import { mapState } from 'vuex'
const fb = require('../firebaseConfig.js')

export default {
  data() {
    return {
      signupForm: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        company: '',
      },
    }
  },
  computed: {
    ...mapState(['currentUser']),
    valid2() {
      if ((this.signupForm.email.length > 6) && (this.signupForm.password.length > 5) && (this.signupForm.firstname.length > 0) && (this.signupForm.firstname.length > 0) && (this.signupForm.phone.length == 14) && (this.signupForm.company)) {
        return true
      }
      else {
        return false
      }
    }
  },
  methods: {
    signup() {
      this.$store.dispatch('signupBusiness', {
        email: this.signupForm.email,
        password: this.signupForm.password,
        firstName: this.signupForm.firstname,
        lastName: this.signupForm.lastname,
        company: this.signupForm.company,
        phone: this.signupForm.phone,
      })
      this.$emit('close')
      setTimeout(() => {
      }, 1000)
    }
  	
  },
}
</script>
