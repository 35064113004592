<template>
	<div class="modal-mask">
		<div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body pa-3">
        	<transition name="fadeStop">
		        <div class="dashboard__container--body--col" v-if="showPasswordReset">
		          <PasswordReset @close="hidePassword()"></PasswordReset>
		        </div>
		      </transition>
		      <transition name="fadeStop">
		        <div v-show="!showPasswordReset">
			        <div :class="{ 'signup-form': !showLoginForm }" >
			          <form v-if="showLoginForm" @submit.prevent>
			          	<div class="flex justify-space-between align-center mb-2">
				            <h2>Welcome Back</h2>
						         	<i class="fas fa-times mr-1" @click="$emit('close')"></i>
				          </div>
			            <div class="mb-3 mt-2">
			              <label for="email1">Email</label>
			              <input v-model.trim="loginForm.email" autocomplete="email" type="text" placeholder="you@email.com" id="email1" minlength="7" required />
			            </div>
			            <div class="mb-3">
			              <label for="password1">Password</label>
			              <input v-model.trim="loginForm.password" autocomplete="current-password" type="password" placeholder="******" id="password1" minlength="6" required />
			            </div>
			            <button @click="login()" class="btn btn__primary mt-3">Log In</button>
			            <div class="extras mt-5">
			              <button class="btn btn__small btn__flat" @click="showPassword()">
			                Forgot Password
			              </button>
			              <button class="btn btn__small btn__flat" @click="toggleForm()">
			                Create an Account
			              </button>
			            </div>
			          </form>
			          <form v-show="!showLoginForm" @submit.prevent>
			          	<div class="flex justify-space-between align-center mb-2">
			            	<h2>Sign Up To Work</h2>
						        <i class="fas fa-times mr-1" @click="$emit('close')"></i>
						      </div>
			            <div class="mb-3">
			              <label for="firstname">First Name</label>
			              <input v-model.trim="signupForm.firstname" autocomplete="given-name" type="text" placeholder="" id="firstname" required />
			            </div>
			            <div class="mb-3">
			              <label for="lastname">Last Name</label>
			              <input v-model.trim="signupForm.lastname" autocomplete="family-name" type="text" placeholder="" id="lastname" required />
			            </div>
			            <div class="mb-3">
			              <label for="phone">Cell Phone</label>
			              <input v-model.trim="signupForm.phone" type="text" v-mask="'(###) ###-####'" placeholder="(###) ###-####" id="phone" required />
			            </div>
			            <div class="mb-3">
			              <label for="email2">Email</label>
			              <input v-model.trim="signupForm.email" autocomplete="email" type="text" placeholder="you@email.com" id="email2" required minlength="7" />
			            </div>
			            <div class="mb-3">
			              <label for="dob">Date of Birth:</label>
			              <input type="date" placeholder="" v-model.trim="signupForm.dob" id="dob" />
			            </div>
			            <!-- <div class="mb-3">
							      <label>Are you fully-vaccinated?</label>
							      <select v-model="signupForm.fullyVaccinated">
							        <option v-for="option in options" v-bind:value="option">
							          {{option}}
							        </option>
							      </select>
							    </div> -->
							    <!-- <div class="mb-3">
			              <label for="howhear">How did you hear about us?</label>
			              <input v-model.trim="signupForm.howhear" type="text" placeholder="" id="howhear" />
			            </div> -->
			            <div class="mb-3">
			              <label for="password2">Password</label>
			              <input v-model.trim="signupForm.password" autocomplete="new-password" type="password" placeholder="min 6 characters" id="password2" required minlength="6"/>
			            </div>
			            <button :disabled="!valid2" @click="signup()" class="btn btn__accent mt-3">Sign Up</button>
			            <div class="extras mt-5">
			              <button class="btn btn__small btn__flat" @click="toggleForm()">
			                I already have an account
			              </button>
			            </div>
			          </form>
			        </div>
			      </div>
		      </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped type="text/css">
  .signup-form {
    width: 96%;
    max-width: 100%;
    margin: auto;
}
</style>

<script>
import PasswordReset from '@/components/PasswordReset'
import { mapState } from 'vuex'
const fb = require('../firebaseConfig.js')

export default {
  data() {
    return {
    	options: ['yes', 'no'],
      loginForm: {
        email: '',
        password: ''
      },
      signupForm: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        dob: ''
      },
      showLoginForm: false,
      showPasswordReset: false
    }
  },
  components: {
    PasswordReset
  },
  computed: {
    ...mapState(['currentUser']),
    valid() {
      if ((this.loginForm.email.length > 6) && (this.loginForm.password.length > 5)) {
        return true
      }
      else {
        return false
      }
    },
    valid2() {
      if ((this.signupForm.email.length > 6) && (this.signupForm.password.length > 5) && (this.signupForm.firstname.length > 0) && (this.signupForm.firstname.length > 0) && (this.signupForm.phone.length == 14) && (this.signupForm.dob)) {
        return true
      }
      else {
        return false
      }
    }
  },
  methods: {
	  toggleForm() {
      this.showLoginForm = !this.showLoginForm
    },
    showPassword() {
      this.showPasswordReset = true
    },
    hidePassword() {
      this.showPasswordReset = false
    },
    login() {
      this.$store.dispatch('login', {
        email: this.loginForm.email,
        password: this.loginForm.password
      })
      this.$emit('close')
    },
   //  onClickOutside(){
			// this.$emit('close')
  	// },
    onUpdateNote: function () {
  		this.performingRequest = true
  		let data = this.firstData
  		console.log(data)
  		fb.userDaysCollection.doc(this.firstData.id).update(data)
			setTimeout(() => {
        this.performingRequest = false
        this.$emit('close')
      }, 1000)
    },
    signup() {
      this.$store.dispatch('signup', {
        email: this.signupForm.email,
        password: this.signupForm.password,
        firstName: this.signupForm.firstname,
        lastName: this.signupForm.lastname,
        phone: this.signupForm.phone,
        dob: this.signupForm.dob,
      })
      this.$emit('close')
      setTimeout(() => {
      }, 1000)
    }
  	
  },
}
</script>
